import * as React from "react";
import Layout from "../../components/layouts/Layout";
import backgroundHex from "../../assets/img/backgroundSections/background.webp";
import FarmHouseCreate from "../../components/FarmHouse/create_page";

const Create = () => {
    return (
        <Layout background={backgroundHex}>
            <FarmHouseCreate />
        </Layout>
    )
}

export default Create;