import * as React from "react";
import { Link } from "gatsby";

import Icons from '../../assets/vector/icons';
import loli from '../../assets/img/farmHouse/loli.webp';
import tractor from '../../assets/img/farmHouse/tractor.webp';

const FHCreate = () => {
    return (
        <div className="p-5">
            <div className="text-xl font-bold">CREATE NEW FARM HOUSE</div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-9 items-center">
                <div className="bg-primaryDark rounded-lg p-4">
                    <div className="bg-white rounded-lg p-2 w-full lg:w-3/4">
                        <div className="flex justify-between">
                            <input type="text" className="w-full outline-0" placeholder="House Name"/>
                            <Icons.pencil/>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="text-center">
                            <div className="h-72 flex justify-center items-center">
                                <img src={tractor} alt="tractor-icon" />
                            </div>
                            <div className="">
                                <button className="bg-buttonBuy rounded-md py-1 px-2 text-white font-medium text-base">SELECT TRACTORS</button>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="h-72 flex justify-center items-center">
                                <img src={loli} alt="waifu-icon" className="brightness-[0.2] hover:brightness-100"/>
                            </div>
                            <div className="btn">
                                <button className="bg-btnGray rounded-md py-1 px-2 text-white font-medium text-base">SELECT FARMER WAIFUS</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-primaryDark rounded-lg p-4 text-white text-center">
                    <div className="text-2xl font-semibold">
                        Total Farm House Power
                    </div>
                    <div className="text-2xl font-semibold my-3">
                        <span>0 <span className="text-primaryskyblue mr-2">Capacity</span></span>
                        <span>0 <span className="text-primarypeach">WP</span></span>
                    </div>
                    <div className="text-base font-semibold">
                        Create a New Farm House cost 20 <span className="text-primarypeach">$PEACH</span>
                    </div>
                    <div className="text-xl font-bold flex flex-col mt-2">
                        <div>
                            <button className="bg-btnGray rounded-md py-1 px-2">CREATE</button>
                        </div>
                        <div>
                            <Link to="/farmHouse">
                                <button className="bg-backgroundCard rounded-md py-1 px-2 mt-1">CANCEL</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FHCreate;